import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import NotFound from "screens/NotFound";
import LoginPage from "screens/Login";
import Layout from "components/Layout";
import Dashboard from "screens/Dashboard";
import SelectOption from "screens/SelectOption";
import FaceScan from "screens/FaceScan";
import FingerScan from "screens/FingerScan";
import Result from "screens/ScanResult";
import UpdatePatientInfo from "screens/UpdatePatientInfo";
import UpdateObservations from "screens/UpdateObservations";
import History from "screens/History";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const jwtPayload = localStorage.getItem("token")?.split(".")[1] ?? "";
  const base64string =
    jwtPayload.replace("-", "+").replace("_", "/") + "=".repeat((4 - (jwtPayload.length % 4)) % 4);
  const { last_login, exp } = base64string.length > 0 ? JSON.parse(atob(base64string)) : {};
  if (
    typeof last_login === "string" &&
    typeof exp === "number" &&
    new Date(last_login.replace(" ", "T")).getTime() + exp > Date.now() &&
    localStorage.getItem("token")?.length > 0
  )
    return children;
  else {
    localStorage.removeItem("emp_id");
    localStorage.removeItem("token");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="select-option" element={<SelectOption />} />
          <Route path="result" element={<Result />} />
          <Route path="history" element={<History />} />
          <Route path="update">
            <Route path="patient-info/:pID" element={<UpdatePatientInfo />} />
            <Route path="observations/:scanID" element={<UpdateObservations />} />
          </Route>
        </Route>
        <Route
          path="face-scan"
          element={
            <RequireAuth>
              <FaceScan />
            </RequireAuth>
          }
        />
        <Route
          path="finger-scan"
          element={
            <RequireAuth>
              <FingerScan />
            </RequireAuth>
          }
        />
        <Route path="login" element={<LoginPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
