const LoadingScreen = () => (
  <div className="py-40 flex items-center justify-center">
    <svg className="w-40" viewBox="0 0 100 100">
      <circle cx="84" cy="50" r="10" fill="#cbd5e1">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="0.5s"
          calcMode="spline"
          keyTimes="0;1"
          values="8;0"
          keySplines="0 0.5 0.5 1"
          begin="0s"
        ></animate>
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2s"
          calcMode="discrete"
          keyTimes="0;0.25;0.5;0.75;1"
          values="#cbd5e1;#0f172a;#334155;#64748b;#cbd5e1"
          begin="0s"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#cbd5e1">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;8;8;8"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        ></animate>
      </circle>
      <circle cx="50" cy="50" r="10" fill="#64748b">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;8;8;8"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.5s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.5s"
        ></animate>
      </circle>
      <circle cx="84" cy="50" r="10" fill="#334155">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;8;8;8"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1s"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#0f172a">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;8;8;8"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.5s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.5s"
        ></animate>
      </circle>
    </svg>
  </div>
);
export default LoadingScreen;
