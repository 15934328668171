import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

const Layout = () => {
  const navigate = useNavigate();
  const [isSideBarOpen, setSideBarOpen] = useState(false);

  return (
    <div className="relative max-w-sm min-h-screen px-6 py-12 mx-auto flex flex-col">
      <header className="mb-6 flex items-center justify-between">
        <h1 className="text-2xl font-medium">Welcome</h1>
        <button type="button" onClick={() => setSideBarOpen(true)}>
          <span className="sr-only">Open Sidebar</span>
          <svg className="h-8 w-8" viewBox="0 0 48 48" fill="currentColor">
            <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" />
          </svg>
        </button>
      </header>

      <Outlet />

      <Transition show={isSideBarOpen}>
        <Dialog
          as={Transition.Child}
          enter="ease-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150 delay-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed inset-0 bg-black/25 flex items-center justify-center"
          onClose={() => setSideBarOpen(false)}
        >
          <Dialog.Panel
            as={Transition.Child}
            enter="ease-out duration-200 delay-150"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="ease-in duration-200"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
            className="fixed inset-y-0 right-0 w-64 p-6 bg-white"
          >
            <Dialog.Title as="header" className="flex items-center justify-between">
              <h1 className="text-2xl font-medium">Menu</h1>
              <button type="button" onClick={() => setSideBarOpen(false)}>
                <span className="sr-only">Close Sidebar</span>
                <svg className="h-8 w-8" viewBox="0 0 48 48" fill="currentColor">
                  <path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z" />
                </svg>
              </button>
            </Dialog.Title>
            <Dialog.Description as="nav" className="mt-4 border-t border-neutral-200">
              <ul>
                <li className="py-3 border-b border-neutral-200">
                  <Link
                    to="/"
                    className="block w-full text-lg"
                    onClick={() => setSideBarOpen(false)}
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="py-3 border-b border-neutral-200">
                  <Link
                    to="/history"
                    className="block w-full text-lg"
                    onClick={() => setSideBarOpen(false)}
                  >
                    History
                  </Link>
                </li>
                <li className="py-3 border-b border-neutral-200">
                  <button
                    type="button"
                    onClick={() => {
                      localStorage.removeItem("emp_id");
                      localStorage.removeItem("token");
                      setSideBarOpen(false);
                      navigate("/login", { replace: true });
                    }}
                    className="block w-full text-lg text-left"
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </Dialog.Description>
          </Dialog.Panel>
        </Dialog>
      </Transition>
    </div>
  );
};
export default Layout;
