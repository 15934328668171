import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { sdkWrapperURL } from "utils/api-url-list";

const UpdatePatientInfo = () => {
  const { pID } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  if (state.patientData.patientID !== pID) navigate(-1);

  const [loading, setLoading] = useState(false);
  const [patientData, setPatientData] = useState(
    () =>
      state?.patientData ?? {
        patientID: "",
        name: "",
        age: "",
        gender: "",
        height: "",
        weight: "",
        waist: "",
      }
  );
  const [error, setError] = useState("");

  const updatePatientInfo = async e => {
    try {
      e.preventDefault();
      setLoading(true);
      setError("");
      if (!(patientData.age?.length > 0 && patientData.age.match(/^[0-9]+$/)))
        throw new Error("Please enter a valid Age");
      if (patientData.age < 18) throw new Error("Age must be at least 18");
      else if (patientData.age > 110) throw new Error("Age can be at most 110");
      if (!(patientData.gender?.length > 0)) throw new Error("Gender must be selected");
      if (patientData.height?.length > 0) {
        if (!/^\d{1,3}(\.\d{1,2})?$/.test(patientData.height))
          throw new Error("Please enter a valid Height");
        if (patientData.height < 30 || patientData.height > 250)
          throw new Error("Height must be between 30 and 250 cms");
      }
      if (patientData.weight?.length > 0) {
        if (!/^\d{1,3}(\.\d{1,2})?$/.test(patientData.weight))
          throw new Error("Please enter a valid Weight");
        if (patientData.weight < 20 || patientData.weight > 220)
          throw new Error("Weight must be between 20 and 220 kgs");
      }
      if (patientData.waist?.length > 0) {
        if (!/^\d{1,2}$/.test(patientData.waist))
          throw new Error("Please enter a valid Waist length");
        if (patientData.waist < 10 || patientData.waist > 80)
          throw new Error("Waist length must be between 10 and 80 inches");
      }
      try {
        const resp = await fetch(sdkWrapperURL("/v2/users/trial"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            employee_id: localStorage.getItem("emp_id"),
            action: "upd_basic_info",
            basic_info: {
              patient_id: patientData.patientID,
              name: patientData.name ?? "",
              age: patientData.age,
              gender: patientData.gender,
              height: patientData.height ?? "",
              weight: patientData.weight ?? "",
              waist: patientData.waist ?? "",
            },
          }),
        });
        const resp_json = await resp.json();
        if (resp_json.statusCode?.toString().startsWith("2")) {
          toast(resp_json.message, {
            type: "success",
            position: "top-center",
            hideProgressBar: false,
            draggable: false,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            closeOnClick: false,
            autoClose: 3000,
          });
          navigate(-1);
        } else throw new Error(resp_json.message);
      } catch (err) {
        toast(err.message, {
          type: "error",
          position: "top-center",
          hideProgressBar: false,
          draggable: false,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          closeOnClick: false,
          autoClose: 3000,
        });
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="mb-6 text-slate-700 text-center text-3xl font-medium">Patient Data</h1>
      <form onSubmit={updatePatientInfo}>
        <div className="mb-3">
          <label htmlFor="patientID" className="block mb-1.5 text-slate-700">
            Patient ID
          </label>
          <input
            className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-neutral-100 text-neutral-700"
            id="patientID"
            name="patientID"
            type="text"
            autoComplete="off"
            disabled
            defaultValue={patientData.patientID?.length > 0 ? patientData.patientID : "--"}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="name" className="block mb-1.5 text-slate-700">
            Patient's Name
          </label>
          <input
            className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700 capitalize"
            id="name"
            name="name"
            type="text"
            autoComplete="off"
            value={patientData.name ?? ""}
            onChange={e => setPatientData(d => ({ ...d, name: e.target.value }))}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="age" className="block mb-1.5 text-slate-700">
            Patient's Age<span style={{ color: "red" }}>*</span>
          </label>
          <input
            className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700"
            id="age"
            name="age"
            type="text"
            inputMode="numeric"
            autoComplete="off"
            value={patientData.age}
            onChange={e => setPatientData(d => ({ ...d, age: e.target.value }))}
          />
        </div>
        <fieldset className="mb-3 p-2 pt-1 grid grid-cols-3 gap-3 rounded-md border border-neutral-300">
          <legend className="px-1.5">
            Gender<span style={{ color: "red" }}>*</span>
          </legend>
          <div>
            <input
              className="hidden peer"
              id="gender-male"
              name="gender"
              type="radio"
              value="male"
              checked={patientData.gender === "male"}
              onChange={e => setPatientData(d => ({ ...d, gender: e.target.value }))}
            />
            <label
              htmlFor="gender-male"
              className="p-2 flex flex-col items-center justify-center rounded-xl border border-neutral-300 bg-white text-slate-700 peer-checked:font-medium peer-checked:border-slate-700 peer-checked:bg-slate-100"
            >
              <svg
                className="flex-shrink-0 h-6 w-6 p-px rounded-full border border-slate-500 bg-gradient-to-br from-slate-50 to-slate-300"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 40 40"
              >
                <path d="M12.15,13.82c0,0-2.26-7.65,5.72-10.63c1.69-0.4,6.44-1.69,10.23,3.87c0.97,2.09,1.29,3.22,0.97,6.85" />
                <path
                  d="M14.01,11.97c0,0,4.51,0.4,4.91-3.22c0.81,1.77,1.13,3.63,8.06,3.38c0,1.53,1.37,9.1-6.28,9.83
	C19.16,21.8,13.6,22.04,14.01,11.97z"
                />
                <path d="M13.93,13.02c0,0-2.34-0.16-2.26,2.26c0.16,1.13,0.73,2.26,2.66,1.85" />
                <path d="M26.9,13.1c0,0,2.82,0.08,2.42,2.26c-0.16,0.97-0.89,2.18-2.58,1.85" />
                <line x1="17.23" y1="20.91" x2="17.23" y2="24.38" />
                <line x1="23.91" y1="20.99" x2="23.91" y2="24.54" />
                <polygon points="15.62,23.01 20.53,27.76 18.52,30.02 13.36,25.1 " />
                <polygon points="25.45,22.93 20.53,27.76 22.71,30.1 27.62,25.18 " />
                <path d="M13.36,25.1c0,0-10.15,0.89-9.18,12.65c4.67,0,32.71,0.08,32.71,0.08s1.85-11.2-9.26-12.65" />
                <line x1="10.94" y1="37.83" x2="10.86" y2="34.77" />
                <line x1="30.2" y1="37.91" x2="30.2" y2="34.85" />
              </svg>
              <span className="mt-1">Male</span>
            </label>
          </div>
          <div>
            <input
              className="hidden peer"
              id="gender-female"
              name="gender"
              type="radio"
              value="female"
              checked={patientData.gender === "female"}
              onChange={e => setPatientData(d => ({ ...d, gender: e.target.value }))}
            />
            <label
              htmlFor="gender-female"
              className="p-2 flex flex-col items-center justify-center rounded-xl border border-neutral-300 bg-white text-slate-700 peer-checked:font-medium peer-checked:border-slate-700 peer-checked:bg-slate-100"
            >
              <svg
                className="flex-shrink-0 h-6 w-6 p-px rounded-full border border-slate-500 bg-gradient-to-br from-slate-50 to-slate-300"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 40 40"
              >
                <path
                  d="M8.74,23.67c0,0,0.08-7.58,0.08-8.92S8.51,3.69,19.88,2.66c1.58,0.16,10.42,0.47,11.14,11.61
	c-0.08,3.16-0.08,9.32-0.08,9.32"
                />
                <path
                  d="M13.48,11.9c0,0,7.42,0.87,8.13-3.63c0.55,2.05,0.55,3.16,4.42,3.55c0,2.21,1.34,8.53-5.84,9.71
	C18.61,21.46,12.61,21.06,13.48,11.9z"
                />
                <path d="M13.32,12.93c0,0-2.29,0-2.13,2.13c0.16,1.03,0.63,1.97,2.53,1.74" />
                <path d="M26.12,13.01c0,0,2.45,0.08,2.37,1.97c-0.08,1.18-1.42,2.21-2.69,1.82" />
                <line x1="16.56" y1="20.59" x2="16.48" y2="23.83" />
                <line x1="23.04" y1="20.67" x2="23.04" y2="23.98" />
                <polygon points="12.77,24.7 15.06,22.56 19.88,27.38 17.9,29.43 " />
                <polygon points="19.88,27.38 24.77,22.56 26.67,24.62 22.01,29.43 " />
                <path d="M12.77,24.7c0,0-9.56,0.24-9.08,12.32c4.5,0.08,32.22,0,32.22,0s1.66-10.82-9.24-12.4" />
                <line x1="29.28" y1="37.17" x2="29.28" y2="34.17" />
                <line x1="10.32" y1="36.94" x2="10.24" y2="34.33" />
                <circle cx="19.84" cy="31.57" r="0.59" />
                <circle cx="19.84" cy="34.38" r="0.59" />
              </svg>
              <span className="mt-1">Female</span>
            </label>
          </div>
          <div>
            <input
              className="hidden peer"
              id="gender-others"
              name="gender"
              type="radio"
              value="others"
              checked={patientData.gender === "others"}
              onChange={e => setPatientData(d => ({ ...d, gender: e.target.value }))}
            />
            <label
              htmlFor="gender-others"
              className="p-2 flex flex-col items-center justify-center rounded-xl border border-neutral-300 bg-white text-slate-700 peer-checked:font-medium peer-checked:border-slate-700 peer-checked:bg-slate-100"
            >
              <svg
                className="flex-shrink-0 h-6 w-6 p-px rounded-full border border-slate-500 bg-gradient-to-br from-slate-50 to-slate-300"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 40 40"
              >
                <ellipse cx="20.62" cy="19.47" rx="10.04" ry="10" />
                <polyline points="14.19,11.83 5.6,3.41 5.69,10.56 " />
                <line x1="5.6" y1="3.41" x2="13.11" y2="3.41" />
                <line x1="14.37" y1="6.03" x2="8.4" y2="12.19" />
                <polyline points="27.22,12.1 36,3.14 27.95,3.23 " />
                <line x1="36" y1="3.14" x2="35.82" y2="10.38" />
                <line x1="20.62" y1="29.47" x2="20.62" y2="38.52" />
                <line x1="15.37" y1="33.99" x2="25.68" y2="34.08" />
              </svg>
              <span className="mt-1">Other</span>
            </label>
          </div>
        </fieldset>
        <div className="mb-3">
          <label htmlFor="height" className="block mb-1.5 text-slate-700">
            Height (cms)
          </label>
          <input
            className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700"
            id="height"
            name="height"
            type="text"
            inputMode="decimal"
            autoComplete="off"
            value={patientData.height ?? ""}
            onChange={e => setPatientData(d => ({ ...d, height: e.target.value }))}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="weight" className="block mb-1.5 text-slate-700">
            Weight (kgs)
          </label>
          <input
            className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700"
            id="weight"
            name="weight"
            type="text"
            inputMode="decimal"
            autoComplete="off"
            value={patientData.weight ?? ""}
            onChange={e => setPatientData(d => ({ ...d, weight: e.target.value }))}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="waist" className="block mb-1.5 text-slate-700">
            Waist (in)
          </label>
          <input
            className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700"
            id="waist"
            name="waist"
            type="text"
            inputMode="numeric"
            autoComplete="off"
            value={patientData.waist ?? ""}
            onChange={e => setPatientData(d => ({ ...d, waist: e.target.value }))}
          />
        </div>
        {error.length > 0 && (
          <p className="mt-3 px-3 py-1.5 rounded-md flex items-center bg-red-200 text-red-600">
            <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 48 48" fill="currentColor">
              <path d="M24 34q.7 0 1.175-.475.475-.475.475-1.175 0-.7-.475-1.175Q24.7 30.7 24 30.7q-.7 0-1.175.475-.475.475-.475 1.175 0 .7.475 1.175Q23.3 34 24 34Zm-1.35-7.65h3V13.7h-3ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z" />
            </svg>
            <span className="ml-2 font-light">{error}</span>
          </p>
        )}
        <div className="mt-6 grid grid-cols-2 gap-6 px-3">
          <button
            type="button"
            disabled={loading}
            onClick={() => navigate(-1)}
            className="px-3 py-1.5 rounded-full bg-white text-slate-700 border border-slate-700 text-xl font-medium"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-3 py-1.5 rounded-full bg-slate-700 text-white border border-slate-700 text-xl font-medium"
          >
            {loading ? (
              <svg className="mx-auto h-6 w-6 animate-spin" fill="currentColor" viewBox="0 0 50 50">
                <path d="M41.9 23.9c-.3-6.1-4-11.8-9.5-14.4-6-2.7-13.3-1.6-18.3 2.6-4.8 4-7 10.5-5.6 16.6 1.3 6 6 10.9 11.9 12.5 7.1 2 13.6-1.4 17.6-7.2-3.6 4.8-9.1 8-15.2 6.9-6.1-1.1-11.1-5.7-12.5-11.7-1.5-6.4 1.5-13.1 7.2-16.4 5.9-3.4 14.2-2.1 18.1 3.7 1 1.4 1.7 3.1 2 4.8.3 1.4.2 2.9.4 4.3.2 1.3 1.3 3 2.8 2.1 1.3-.8 1.2-2.5 1.1-3.8 0-.4.1.7 0 0z" />
              </svg>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdatePatientInfo;
