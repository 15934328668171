import { Disclosure } from "@headlessui/react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { sdkWrapperURL } from "utils/api-url-list";

const UpdateObservations = () => {
  const { scanID } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  if (state.scan_id !== scanID) navigate(-1);

  const [loading, setLoading] = useState(false);
  const [observations, setObservations] = useState(() =>
    state?.observations?.length > 0
      ? state.observations
      : [
          {
            heart_rate: "",
            resp_rate: "",
            spo2: "",
            bp_sys: "",
            bp_dia: "",
            rmssd: "",
            sdnn: "",
            comment: "",
          },
        ]
  );

  const updateObservation = (index = 0, key = "", value = "") =>
    setObservations(obv => {
      obv[index][key] = value;
      return [...obv];
    });

  const addObservation = () =>
    setObservations(obv => [
      ...obv,
      {
        heart_rate: "",
        resp_rate: "",
        spo2: "",
        bp_sys: "",
        bp_dia: "",
        rmssd: "",
        sdnn: "",
        comment: "",
      },
    ]);

  const deleteObservation = index =>
    setObservations(obv => [...obv.slice(0, index), ...obv.slice(index + 1)]);

  const saveObservations = async () => {
    try {
      setLoading(true);
      if (observations.length <= 0) throw new Error("There should be at least one Observation.");
      const filteredObservations = observations.filter(obv =>
        Object.values(obv).some(v => v !== "")
      );
      if (filteredObservations.length > 0) {
        filteredObservations.forEach((obv, obv_idx) =>
          Object.entries(obv).forEach(([key, value]) => {
            const texts = {
              heart_rate: "Heart Rate",
              resp_rate: "Respiration Rate",
              spo2: "Oxygen Saturation",
              bp_sys: "Blood Pressure (Systolic)",
              bp_dia: "Blood Pressure (Diastolic)",
              sdnn: "HRV (SDNN)",
              rmssd: "HRV (RMSSD)",
            };
            if (value) {
              if (key === "sdnn" || key === "rmssd") {
                if (!value.toString().match(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/))
                  throw new Error(`${texts[key]} in Observation ${obv_idx + 1} is invalid`);
              } else if (key !== "comment") {
                if (!value.toString().match(/^[0-9]+$/))
                  throw new Error(`${texts[key]} in Observation ${obv_idx + 1} is invalid`);
              }
            }
          })
        );
        const resp = await fetch(sdkWrapperURL("/v2/users/trial"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            employee_id: localStorage.getItem("emp_id"),
            action: "upd_observations",
            scan_id: state.scan_id,
            observations: filteredObservations,
          }),
        });
        const resp_json = await resp.json();
        if (resp_json.statusCode?.toString().startsWith("2")) {
          toast(resp_json.message, {
            type: "success",
            position: "top-center",
            hideProgressBar: false,
            draggable: false,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            closeOnClick: false,
            autoClose: 3000,
          });
          navigate(-1);
        } else throw new Error(resp_json.message);
      } else throw new Error("At least one Observation should be filled.");
    } catch (err) {
      toast(err.message, {
        type: "error",
        position: "top-center",
        hideProgressBar: false,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        closeOnClick: false,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="mb-6 text-slate-700 text-center text-3xl font-medium">Observations</h1>
      <div className="mb-4 p-4 rounded-md bg-slate-200 text-slate-700 overflow-hidden">
        <strong className="text-slate-500 text-xs">
          {state?.date
            ? new Date(state.date?.replace(" ", "T")).toLocaleString("en", {
                dateStyle: "long",
                timeStyle: "medium",
              })
            : "--"}
        </strong>
        <p className="text-xl font-medium capitalize truncate">{state?.patientID ?? "--"}</p>
      </div>
      <div>
        {observations.map((observation, index) => (
          <Disclosure
            key={"observation-" + index}
            as="div"
            defaultOpen={index === observations.length - 1}
            className="mt-4 p-4 rounded-md bg-slate-200 text-slate-700"
          >
            <div className="flex items-center space-x-3">
              <Disclosure.Button className="w-full flex items-center justify-between text-left">
                {({ open }) => (
                  <>
                    <span className="text-lg font-medium">Observation {index + 1}</span>
                    <svg
                      className={
                        "flex-shrink-0 h-5 w-5 ease-in-out duration-150 " +
                        (open ? "rotate-180" : "rotate-0")
                      }
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                  </>
                )}
              </Disclosure.Button>
              <button
                type="button"
                className="flex-shrink-0"
                onClick={() => deleteObservation(index)}
              >
                <span className="sr-only">Delete Observation {index + 1}</span>
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <Disclosure.Panel className="mt-3 grid grid-cols-2 gap-4 text-sm">
              <div>
                <label
                  htmlFor={"heart_rate-observation-" + index}
                  className="block mb-1.5 text-slate-700"
                >
                  Heart Rate
                </label>
                <input
                  className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700"
                  id={"heart_rate-observation-" + index}
                  name={"heart_rate-observation-" + index}
                  type="text"
                  inputMode="numeric"
                  autoComplete="off"
                  value={observation.heart_rate}
                  onChange={e => {
                    if (e.target.value.length <= 5)
                      updateObservation(index, e.target.id.split("-")[0], e.target.value);
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor={"resp_rate-observation-" + index}
                  className="block mb-1.5 text-slate-700"
                >
                  Respiration Rate
                </label>
                <input
                  className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700"
                  id={"resp_rate-observation-" + index}
                  name={"resp_rate-observation-" + index}
                  type="text"
                  inputMode="numeric"
                  autoComplete="off"
                  value={observation.resp_rate}
                  onChange={e => {
                    if (e.target.value.length <= 5)
                      updateObservation(index, e.target.id.split("-")[0], e.target.value);
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor={"spo2-observation-" + index}
                  className="block mb-1.5 text-slate-700"
                >
                  Oxygen Saturation
                </label>
                <input
                  className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700"
                  id={"spo2-observation-" + index}
                  name={"spo2-observation-" + index}
                  type="text"
                  inputMode="numeric"
                  autoComplete="off"
                  value={observation.spo2}
                  onChange={e => {
                    if (e.target.value.length <= 5)
                      updateObservation(index, e.target.id.split("-")[0], e.target.value);
                  }}
                />
              </div>
              <div />
              <div>
                <label
                  htmlFor={"bp_sys-observation-" + index}
                  className="block mb-1.5 text-slate-700"
                >
                  Blood Pressure
                  <br />
                  (Systolic)
                </label>
                <input
                  className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700"
                  id={"bp_sys-observation-" + index}
                  name={"bp_sys-observation-" + index}
                  type="text"
                  inputMode="numeric"
                  autoComplete="off"
                  value={observation.bp_sys}
                  onChange={e => {
                    if (e.target.value.length <= 5)
                      updateObservation(index, e.target.id.split("-")[0], e.target.value);
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor={"bp_dia-observation-" + index}
                  className="block mb-1.5 text-slate-700"
                >
                  Blood Pressure
                  <br />
                  (Diastolic)
                </label>
                <input
                  className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700"
                  id={"bp_dia-observation-" + index}
                  name={"bp_dia-observation-" + index}
                  type="text"
                  inputMode="numeric"
                  autoComplete="off"
                  value={observation.bp_dia}
                  onChange={e => {
                    if (e.target.value.length <= 5)
                      updateObservation(index, e.target.id.split("-")[0], e.target.value);
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor={"sdnn-observation-" + index}
                  className="block mb-1.5 text-slate-700"
                >
                  HRV (SDNN)
                </label>
                <input
                  className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700"
                  id={"sdnn-observation-" + index}
                  name={"sdnn-observation-" + index}
                  type="text"
                  inputMode="decimal"
                  autoComplete="off"
                  value={observation.sdnn}
                  onChange={e => {
                    if (e.target.value.length <= 7)
                      updateObservation(index, e.target.id.split("-")[0], e.target.value);
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor={"rmssd-observation-" + index}
                  className="block mb-1.5 text-slate-700"
                >
                  HRV (RMSSD)
                </label>
                <input
                  className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700"
                  id={"rmssd-observation-" + index}
                  name={"rmssd-observation-" + index}
                  type="text"
                  inputMode="decimal"
                  autoComplete="off"
                  value={observation.rmssd}
                  onChange={e => {
                    if (e.target.value.length <= 7)
                      updateObservation(index, e.target.id.split("-")[0], e.target.value);
                  }}
                />
              </div>
              <div className="col-span-full">
                <label
                  htmlFor={"comment-observation-" + index}
                  className="block mb-1.5 text-slate-700"
                >
                  Comment
                </label>
                <textarea
                  className="block w-full px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700"
                  name={"comment-observation-" + index}
                  id={"comment-observation-" + index}
                  rows={5}
                  autoComplete="off"
                  value={observation.comment}
                  onChange={e => {
                    updateObservation(index, e.target.id.split("-")[0], e.target.value);
                  }}
                />
              </div>
            </Disclosure.Panel>
          </Disclosure>
        ))}
        <button
          type="button"
          onClick={addObservation}
          className="mt-6 mx-auto flex items-center underline underline-offset-2 text-slate-700 font-medium"
        >
          <svg className="flex-shrink-0 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
              fillRule="evenodd"
              clipRule="evenodd"
            />
          </svg>
          <span className="ml-1">Add another Observation</span>
        </button>
        <div className="mt-6 grid grid-cols-2 gap-6 px-3">
          <button
            type="button"
            disabled={loading}
            onClick={() => navigate(-1)}
            className="px-3 py-1.5 rounded-full bg-white text-slate-700 border border-slate-700 text-xl font-medium"
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={loading}
            onClick={saveObservations}
            className="px-3 py-1.5 rounded-full bg-slate-700 text-white border border-slate-700 text-xl font-medium"
          >
            {loading ? (
              <svg className="mx-auto h-6 w-6 animate-spin" fill="currentColor" viewBox="0 0 50 50">
                <path d="M41.9 23.9c-.3-6.1-4-11.8-9.5-14.4-6-2.7-13.3-1.6-18.3 2.6-4.8 4-7 10.5-5.6 16.6 1.3 6 6 10.9 11.9 12.5 7.1 2 13.6-1.4 17.6-7.2-3.6 4.8-9.1 8-15.2 6.9-6.1-1.1-11.1-5.7-12.5-11.7-1.5-6.4 1.5-13.1 7.2-16.4 5.9-3.4 14.2-2.1 18.1 3.7 1 1.4 1.7 3.1 2 4.8.3 1.4.2 2.9.4 4.3.2 1.3 1.3 3 2.8 2.1 1.3-.8 1.2-2.5 1.1-3.8 0-.4.1.7 0 0z" />
              </svg>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateObservations;
