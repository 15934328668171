import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Dialog } from "@headlessui/react";
import { api_key, sdkWrapperURL } from "utils/api-url-list";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedPage, setSelectedPage] = useState(0);

  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [otpTimer, setOTPTimer] = useState(0);
  const [error, setError] = useState("");

  const [isHelpModalOpen, setHelpModalOpen] = useState(false);

  const sendOTP = async event => {
    try {
      event.preventDefault();
      setLoading(true);
      setError("");
      if (phoneNumber.length <= 0) throw new Error("Please enter a Phone Number");
      else if (!phoneNumber.match(/^\d{10}$/)) throw new Error("Incorrect Phone Number");
      else {
        const resp = await fetch(sdkWrapperURL("/v2/auth/send-otp"), {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            action: "send",
            mobile_no: phoneNumber,
            channel_id: "login_mobile",
            email: "",
            api_key,
          }),
        });
        const resp_json = await resp.json();
        if (resp_json.statusCode?.toString().startsWith("2")) {
          setOTPTimer(100);
          setOTP("");
          setSelectedPage(1);
          toast("OTP Sent Successfully", {
            type: "success",
            position: "top-center",
            hideProgressBar: false,
            draggable: false,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            closeOnClick: false,
            autoClose: 3000,
          });
        } else throw new Error(resp_json.message);
      }
    } catch (err) {
      cancelOTP();
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const verifyOTP = async event => {
    try {
      event.preventDefault();
      setLoading(true);
      setError("");
      if (otp.length <= 0) throw new Error("Please enter a OTP");
      else if (!otp.match(/[0-9]{6}/)) throw new Error("OTP should be 6 Digits");
      else {
        const resp = await fetch(sdkWrapperURL("/v2/auth/sign-in"), {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            mobile_no: phoneNumber,
            password: "",
            login_type: "session",
            login_method: "otp_mobile",
            email: "",
            otp: otp,
            api_key,
          }),
        });
        const resp_json = await resp.json();
        if (resp_json.statusCode?.toString().startsWith("2")) {
          localStorage.setItem("emp_id", phoneNumber);
          localStorage.setItem("token", resp_json.user?.id_token);
          navigate(location.state?.from?.pathname || "/", { replace: true });
        } else throw new Error(resp_json.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const cancelOTP = () => {
    setOTPTimer(0);
    setOTP("");
    setError("");
    setSelectedPage(0);
  };

  useEffect(() => {
    let timer = undefined;
    if (typeof otpTimer === "number") {
      if (otpTimer > 0) timer = setTimeout(() => setOTPTimer(t => t - 1), 1000);
      else {
        setOTPTimer(0);
        setOTP("--- OTP Expired ---");
        setError("");
      }
    }
    return () => {
      clearTimeout(timer);
    };
  }, [otpTimer]);

  useEffect(() => {
    if (localStorage.getItem("token")?.length > 0)
      navigate(location.state?.from?.pathname || "/", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="max-w-sm min-h-screen px-6 py-12 mx-auto flex flex-col justify-around">
      <svg className="mx-auto w-4/5" viewBox="0 0 869.99994 520.13854">
        <path
          d="M831.09242,704.18737c-11.13833-9.4118-17.90393-24.27967-16.12965-38.75366s12.76358-27.78,27.01831-30.85364,30.50415,5.43465,34.83378,19.3594c2.3828-26.84637,5.12854-54.81757,19.40179-77.67976,12.92407-20.70115,35.3088-35.51364,59.5688-38.16357s49.80265,7.35859,64.93272,26.50671,18.83461,46.98549,8.2379,68.96911c-7.80623,16.19456-22.188,28.24676-37.2566,38.05184a240.45181,240.45181,0,0,1-164.45376,35.97709Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#f2f2f2"
        />
        <path
          d="M996.72788,546.00953a393.41394,393.41394,0,0,0-54.82622,54.44229,394.561,394.561,0,0,0-61.752,103.194c-1.112,2.72484,3.31272,3.911,4.4123,1.21642A392.34209,392.34209,0,0,1,999.96343,549.24507c2.28437-1.86015-.97-5.08035-3.23555-3.23554Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#fff"
        />
        <path
          d="M445.06712,701.63014c15.2985-12.92712,24.591-33.34815,22.15408-53.22817s-17.53079-38.15588-37.10966-42.37749-41.89745,7.46449-47.8442,26.59014c-3.27278-36.87349-7.04406-75.29195-26.64837-106.69317-17.75122-28.433-48.49666-48.778-81.81777-52.41768s-68.40395,10.107-89.18511,36.407-25.86934,64.53459-11.31476,94.72909c10.72185,22.24324,30.47528,38.79693,51.17195,52.26422,66.02954,42.9653,147.93912,60.88443,225.8773,49.41454"
          transform="translate(-165.00003 -189.93073)"
          fill="#f2f2f2"
        />
        <path
          d="M217.56676,484.37281a540.35491,540.35491,0,0,1,75.30383,74.77651A548.0761,548.0761,0,0,1,352.25665,647.04a545.835,545.835,0,0,1,25.43041,53.8463c1.52726,3.74257-4.55,5.37169-6.06031,1.67075a536.35952,536.35952,0,0,0-49.009-92.727A539.73411,539.73411,0,0,0,256.889,528.63168a538.44066,538.44066,0,0,0-43.76626-39.81484c-3.13759-2.55492,1.33232-6.97788,4.444-4.444Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#fff"
        />
        <path
          d="M789.5,708.93073h-365v-374.5c0-79.67773,64.82227-144.5,144.49976-144.5h76.00049c79.67749,0,144.49975,64.82227,144.49975,144.5Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#f2f2f2"
        />
        <path
          d="M713.5,708.93073h-289v-374.5a143.38177,143.38177,0,0,1,27.59571-84.94434c.66381-.90478,1.32592-1.79785,2.00878-2.68115a144.46633,144.46633,0,0,1,30.75415-29.85058c.65967-.48,1.322-.95166,1.99415-1.42334a144.15958,144.15958,0,0,1,31.47216-16.459c.66089-.25049,1.33374-.50146,2.00659-.74219a144.01979,144.01979,0,0,1,31.1084-7.33593c.65772-.08985,1.333-.16016,2.0083-.23047a146.28769,146.28769,0,0,1,31.10547,0c.67334.07031,1.34864.14062,2.01416.23144a143.995,143.995,0,0,1,31.10034,7.335c.6731.24073,1.346.4917,2.00879.74268a143.79947,143.79947,0,0,1,31.10645,16.21582c.67163.46143,1.344.93311,2.00635,1.40478a145.987,145.987,0,0,1,18.38354,15.564,144.305,144.305,0,0,1,12.72437,14.55078c.68066.88037,1.34277,1.77344,2.00537,2.67676A143.38227,143.38227,0,0,1,713.5,334.43073Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#ccc"
        />
        <circle cx="524.99994" cy="335.5" r="16" fill="#334155" />
        <polygon
          points="594.599 507.783 582.339 507.783 576.506 460.495 594.601 460.496 594.599 507.783"
          fill="#ffb8b8"
        />
        <path
          d="M573.58165,504.27982h23.64384a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H558.69478a0,0,0,0,1,0,0v0a14.88688,14.88688,0,0,1,14.88688-14.88688Z"
          fill="#2f2e41"
        />
        <polygon
          points="655.599 507.783 643.339 507.783 637.506 460.495 655.601 460.496 655.599 507.783"
          fill="#ffb8b8"
        />
        <path
          d="M634.58165,504.27982h23.64384a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H619.69478a0,0,0,0,1,0,0v0a14.88688,14.88688,0,0,1,14.88688-14.88688Z"
          fill="#2f2e41"
        />
        <path
          d="M698.09758,528.60035a10.74272,10.74272,0,0,1,4.51052-15.84307l41.67577-114.86667L764.791,409.082,717.20624,518.85271a10.80091,10.80091,0,0,1-19.10866,9.74764Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#ffb8b8"
        />
        <path
          d="M814.33644,550.1843a10.74269,10.74269,0,0,1-2.89305-16.21659L798.53263,412.4583l23.33776,1.06622L827.23606,533.045a10.80091,10.80091,0,0,1-12.89962,17.13934Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#ffb8b8"
        />
        <circle cx="612.1058" cy="162.12254" r="24.56103" fill="#ffb8b8" />
        <path
          d="M814.17958,522.54937H740.13271l.08911-.57617c.13306-.86133,13.19678-86.439,3.56177-114.436a11.813,11.813,0,0,1,6.06933-14.5835h.00025c13.77173-6.48535,40.20752-14.47119,62.52,4.90918a28.23448,28.23448,0,0,1,9.45947,23.396Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#334155"
        />
        <path
          d="M754.35439,448.1812,721.01772,441.418l15.62622-37.02978a13.99723,13.99723,0,0,1,27.10571,6.99755Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#334155"
        />
        <path
          d="M797.05043,460.73882l-2.00415-45.94141c-1.51977-8.63623,3.42408-16.80029,11.02735-18.13476,7.60547-1.32959,15.03174,4.66016,16.55835,13.35986l7.533,42.92774Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#334155"
        />
        <path
          d="M811.71606,517.04933c11.91455,45.37671,13.21436,103.0694,10,166l-16-2-29-120-16,122-18-1c-5.37744-66.02972-10.61328-122.71527-2-160Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#2f2e41"
        />
        <path
          d="M793.2891,371.03474c-4.582,4.88079-13.09131,2.26067-13.68835-4.40717a8.05467,8.05467,0,0,1,.01014-1.55569c.30826-2.95357,2.01461-5.63506,1.60587-8.7536a4.59046,4.59046,0,0,0-.84011-2.14892c-3.65124-4.88933-12.22227,2.18687-15.6682-2.23929-2.113-2.714.3708-6.98713-1.25065-10.02051-2.14006-4.00358-8.47881-2.0286-12.45388-4.22116-4.42275-2.43948-4.15822-9.22524-1.24686-13.35269,3.55052-5.03359,9.77572-7.71951,15.92336-8.10661s12.25292,1.27475,17.99229,3.51145c6.52109,2.54134,12.98768,6.05351,17.00067,11.78753,4.88021,6.97317,5.34986,16.34793,2.90917,24.50174C802.09785,360.98987,797.03077,367.04906,793.2891,371.03474Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#2f2e41"
        />
        <path
          d="M1004.98163,709.57417h-738.294a1.19069,1.19069,0,0,1,0-2.38137h738.294a1.19069,1.19069,0,0,1,0,2.38137Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#3f3d56"
        />
        <path
          d="M634,600.43073H504a6.46539,6.46539,0,0,1-6.5-6.41531V303.846a6.46539,6.46539,0,0,1,6.5-6.41531H634a6.46539,6.46539,0,0,1,6.5,6.41531V594.01542A6.46539,6.46539,0,0,1,634,600.43073Z"
          transform="translate(-165.00003 -189.93073)"
          fill="#fff"
        />
        <rect x="332.49994" y="201.38965" width="143" height="2" fill="#ccc" />
        <rect x="332.99994" y="315.5" width="143" height="2" fill="#ccc" />
        <rect x="377.49994" y="107.5" width="2" height="304" fill="#ccc" />
        <rect x="427.49994" y="107.5" width="2" height="304" fill="#ccc" />
      </svg>

      {selectedPage === 0 && (
        <form onSubmit={sendOTP} className="mt-4 flex flex-col">
          <h2 className="text-slate-700 text-3xl font-semibold">Login</h2>
          <input
            className="mt-3 px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700 text-xl"
            id="phoneNumber"
            name="phoneNumber"
            type="tel"
            inputMode="numeric"
            autoComplete="tel-national"
            placeholder="Enter Phone Number"
            value={phoneNumber}
            onChange={e => {
              if (e.target.value.length <= 10) setPhoneNumber(e.target.value);
            }}
          />
          {error.length > 0 && (
            <p className="mt-3 px-3 py-1.5 rounded-md flex items-center bg-red-200 text-red-600">
              <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 48 48" fill="currentColor">
                <path d="M24 34q.7 0 1.175-.475.475-.475.475-1.175 0-.7-.475-1.175Q24.7 30.7 24 30.7q-.7 0-1.175.475-.475.475-.475 1.175 0 .7.475 1.175Q23.3 34 24 34Zm-1.35-7.65h3V13.7h-3ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z" />
              </svg>
              <span className="ml-2 font-light">{error}</span>
            </p>
          )}
          <div className="mt-6 flex items-center justify-between">
            <button
              type="button"
              onClick={() => setHelpModalOpen(true)}
              className="text-slate-700 text-xl font-medium"
            >
              Need Help?
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-3 py-1.5 rounded-full bg-slate-700 text-white border border-slate-700 text-xl font-medium"
            >
              {loading ? (
                <svg className="mx-6 h-6 w-6 animate-spin" fill="currentColor" viewBox="0 0 50 50">
                  <path d="M41.9 23.9c-.3-6.1-4-11.8-9.5-14.4-6-2.7-13.3-1.6-18.3 2.6-4.8 4-7 10.5-5.6 16.6 1.3 6 6 10.9 11.9 12.5 7.1 2 13.6-1.4 17.6-7.2-3.6 4.8-9.1 8-15.2 6.9-6.1-1.1-11.1-5.7-12.5-11.7-1.5-6.4 1.5-13.1 7.2-16.4 5.9-3.4 14.2-2.1 18.1 3.7 1 1.4 1.7 3.1 2 4.8.3 1.4.2 2.9.4 4.3.2 1.3 1.3 3 2.8 2.1 1.3-.8 1.2-2.5 1.1-3.8 0-.4.1.7 0 0z" />
                </svg>
              ) : (
                "Send OTP"
              )}
            </button>
          </div>
        </form>
      )}

      {selectedPage === 1 && (
        <form
          onSubmit={typeof otpTimer !== "number" || otpTimer <= 0 ? sendOTP : verifyOTP}
          className="flex flex-col"
        >
          <div className="flex items-center justify-between">
            <h2 className="text-slate-700 text-3xl font-semibold">Verify OTP</h2>
            <p className="text-slate-500 text-lg">
              {otpTimer === 0 ? (
                <span className="text-red-600">Timed Out</span>
              ) : (
                `${Math.floor(otpTimer / 60)}:${(otpTimer % 60).toString().padStart(2, 0)}`
              )}
            </p>
          </div>
          <input
            className="mt-3 px-3 py-1.5 rounded-md outline-none border border-neutral-300 bg-white text-neutral-700 text-xl"
            id="otp"
            name="otp"
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            autoFocus
            placeholder="Enter 6 Digit OTP"
            value={otp}
            onChange={e => {
              if (e.target.value.length <= 6) setOTP(e.target.value);
            }}
            disabled={typeof otpTimer !== "number" || otpTimer <= 0}
          />
          {error.length > 0 && (
            <p className="mt-3 px-3 py-1.5 rounded-md flex items-center bg-red-200 text-red-600">
              <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 48 48" fill="currentColor">
                <path d="M24 34q.7 0 1.175-.475.475-.475.475-1.175 0-.7-.475-1.175Q24.7 30.7 24 30.7q-.7 0-1.175.475-.475.475-.475 1.175 0 .7.475 1.175Q23.3 34 24 34Zm-1.35-7.65h3V13.7h-3ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z" />
              </svg>
              <span className="ml-2 font-light">{error}</span>
            </p>
          )}
          <div className="mt-6 flex items-center justify-between">
            <button
              type="button"
              disabled={loading}
              onClick={cancelOTP}
              className="px-3 py-1.5 rounded-full bg-white text-slate-700 border border-slate-700 text-xl font-medium"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-3 py-1.5 rounded-full bg-slate-700 text-white border border-slate-700 text-xl font-medium"
            >
              {loading ? (
                <svg className="mx-6 h-6 w-6 animate-spin" fill="currentColor" viewBox="0 0 50 50">
                  <path d="M41.9 23.9c-.3-6.1-4-11.8-9.5-14.4-6-2.7-13.3-1.6-18.3 2.6-4.8 4-7 10.5-5.6 16.6 1.3 6 6 10.9 11.9 12.5 7.1 2 13.6-1.4 17.6-7.2-3.6 4.8-9.1 8-15.2 6.9-6.1-1.1-11.1-5.7-12.5-11.7-1.5-6.4 1.5-13.1 7.2-16.4 5.9-3.4 14.2-2.1 18.1 3.7 1 1.4 1.7 3.1 2 4.8.3 1.4.2 2.9.4 4.3.2 1.3 1.3 3 2.8 2.1 1.3-.8 1.2-2.5 1.1-3.8 0-.4.1.7 0 0z" />
                </svg>
              ) : typeof otpTimer === "number" && otpTimer > 0 ? (
                "Verify OTP"
              ) : (
                "Resend OTP"
              )}
            </button>
          </div>
        </form>
      )}

      <Dialog
        open={isHelpModalOpen}
        onClose={() => setHelpModalOpen(false)}
        className="fixed inset-0 bg-black/25 p-4 flex items-center justify-center"
      >
        <Dialog.Panel className="max-w-sm p-4 bg-white rounded-lg">
          <Dialog.Title as="div" className="flex justify-between">
            <h2 className="text-slate-700 text-3xl font-semibold">Need Help?</h2>
            <button
              type="button"
              onClick={() => setHelpModalOpen(false)}
              className="bg-slate-700 text-white font-semibold rounded-full p-1"
            >
              <span className="sr-only">Close Modal</span>
              <svg className="h-5 w-5" viewBox="0 0 48 48" fill="currentColor">
                <path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z" />
              </svg>
            </button>
          </Dialog.Title>
          <Dialog.Description
            as="a"
            href="mailto:connect@careplix.com"
            className="mt-6 flex items-center justify-between p-3 rounded-lg bg-slate-200"
          >
            <div className="mr-8 text-slate-700 text-xl">
              <h4>Email:</h4>
              <h6>connect@careplix.com</h6>
            </div>
            <svg className="h-10 w-10 text-slate-900" viewBox="0 0 48 48" fill="currentColor">
              <path d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm17-15.1L7 13.75V37h34V13.75Zm0-3L40.8 11H7.25ZM7 13.75V11v26Z" />
            </svg>
          </Dialog.Description>
          <Dialog.Description
            as="a"
            href="tel:033-41816533"
            className="mt-3 flex items-center justify-between p-3 rounded-lg bg-slate-200"
          >
            <div className="mr-8 text-slate-700 text-xl">
              <h4>Phone:</h4>
              <h6>033-41816533</h6>
            </div>
            <svg className="h-10 w-10 text-slate-900" viewBox="0 0 48 48" fill="currentColor">
              <path d="M39.75 42q-6.1 0-12.125-3T16.8 31.2Q12 26.4 9 20.375 6 14.35 6 8.25q0-.95.65-1.6Q7.3 6 8.25 6h7q.7 0 1.225.475.525.475.675 1.275l1.35 6.3q.1.7-.025 1.275t-.525.975l-5 5.05q2.8 4.65 6.275 8.1Q22.7 32.9 27.1 35.3l4.75-4.9q.5-.55 1.15-.775.65-.225 1.3-.075l5.95 1.3q.75.15 1.25.75T42 33v6.75q0 .95-.65 1.6-.65.65-1.6.65Zm-28.3-23.4 4.05-4.1L14.35 9H9q0 1.95.6 4.275t1.85 5.325ZM29.9 36.75q2.05.95 4.45 1.55 2.4.6 4.65.7v-5.35l-5.15-1.05ZM11.45 18.6ZM29.9 36.75Z" />
            </svg>
          </Dialog.Description>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};

export default LoginPage;
