import { Link, Navigate, useLocation } from "react-router-dom";

const Result = () => {
  const { result } = useLocation().state ?? {};

  return typeof result === "undefined" ? (
    <Navigate to="/" replace />
  ) : (
    <div>
      <div className="py-6 text-center">
        <h1 className="text-slate-700  text-3xl font-medium">Result</h1>
        <p className="mt-2 text-xs font-light">
          Patient ID:&nbsp;{result.notes?.basic_info?.patient_id ?? ""}
        </p>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-4">
        <div className="p-3 rounded-2xl border border-neutral-300 text-slate-700 text-center">
          <h3 className="text-xl font-semibold">{result.vitals?.heart_rate ?? "--"}</h3>
          <h4 className="text-xs">bpm</h4>
          <p className="mt-1.5 text-sm text-slate-500 font-light">Heart Rate</p>
        </div>
        <div className="p-3 rounded-2xl border border-neutral-300 text-slate-700 text-center">
          <h3 className="text-xl font-semibold">{result.vitals?.resp_rate ?? "--"}</h3>
          <h4 className="text-xs">breathes/min</h4>
          <p className="mt-1.5 text-sm text-slate-500 font-light">Resp Rate</p>
        </div>
        <div className="p-3 rounded-2xl border border-neutral-300 text-slate-700 text-center">
          <h3 className="text-xl font-semibold">{result.vitals?.oxy_sat_prcnt ?? "--"}</h3>
          <h4 className="text-xs">%</h4>
          <p className="mt-1.5 text-sm text-slate-500 font-light">Oxygen Sat</p>
        </div>
        <div className="p-3 rounded-2xl border border-neutral-300 text-slate-700 text-center">
          <h3 className="text-xl font-semibold">
            {result.vitals?.bp_sys ?? "--"} / {result.vitals?.bp_dia ?? "--"}
          </h3>
          <h4 className="text-xs">mmHg</h4>
          <p className="mt-1.5 text-sm text-slate-500 font-light">Blood Pressure</p>
        </div>
        <div className="p-3 rounded-2xl border border-neutral-300 text-slate-700 text-center">
          <h3 className="text-xl font-semibold">
            {result.metadata?.heart_scores?.sdnn?.toFixed?.(2) ?? "--"}
          </h3>
          <h4 className="text-xs">msec</h4>
          <p className="mt-1.5 text-sm text-slate-500 font-light">SDNN</p>
        </div>
        <div className="p-3 rounded-2xl border border-neutral-300 text-slate-700 text-center">
          <h3 className="text-xl font-semibold">
            {result.metadata?.heart_scores?.rmssd?.toFixed?.(2) ?? "--"}
          </h3>
          <h4 className="text-xs">msec</h4>
          <p className="mt-1.5 text-sm text-slate-500 font-light">RMSSD</p>
        </div>
      </div>

      <div className="mt-10 space-y-6">
        <Link
          to={"/update/observations/" + result.scan_id}
          state={{
            scan_id: result.scan_id,
            date: result.entry_time ?? "",
            patientID: result.notes?.basic_info?.patient_id ?? "",
            observations: result.notes?.observations ?? [],
          }}
          className="px-4 py-2 flex items-center justify-center rounded-full bg-slate-700 text-white border border-slate-700 text-sm text-center font-medium"
        >
          Enter Observations
        </Link>
        <Link
          to="/select-option"
          replace
          state={{
            patientData: {
              patientID: result.notes?.basic_info?.patient_id ?? "",
              name: result.notes?.basic_info?.name ?? "",
              age: result.notes?.basic_info?.age ?? "",
              gender: result.notes?.basic_info?.gender ?? "",
              height: result.notes?.basic_info?.height ?? "",
              weight: result.notes?.basic_info?.weight ?? "",
              waist: result.notes?.basic_info?.waist ?? "",
            },
          }}
          className="px-4 py-2 flex items-center justify-center rounded-full bg-slate-700 text-white border border-slate-700 text-sm text-center font-medium"
        >
          Scan Again
        </Link>
        <Link
          to="/select-option"
          replace
          className="px-4 py-2 flex items-center justify-center rounded-full bg-slate-700 text-white border border-slate-700 text-sm text-center font-medium"
        >
          New Scan
        </Link>
      </div>
    </div>
  );
};

export default Result;
